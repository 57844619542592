import Cookies from 'js-cookie';

// 用户信息
const TokenKey = 'Admin-Token';

export function getToken() {
    return Cookies.get(TokenKey);
}

export function setToken(token) {
    return Cookies.set(TokenKey, token);
}

export function removeToken() {
    return Cookies.remove(TokenKey);
}

const USER_DATA_KEY = {
    Token: 'localStorageAccountToken',
    accountType: 'localStorageAccountType',
    accountName: 'localStorageAccountName',
    allowRouter: 'localStorageAccountallowRouter'
};

export function getAccountLoginStatus() {
    // console.log("[auth.js]获取用户信息")
    if (Cookies.get(USER_DATA_KEY.Token)) {
        return {
            Token: Cookies.get(USER_DATA_KEY.Token),
            accountType: Cookies.get(USER_DATA_KEY.accountType)
        };
    } else {
        return null;
    }
}

export function getAccountData() {
    // console.log("[auth.js]获取用户信息")
    if (Cookies.get(USER_DATA_KEY.Token)) {
        return {
            accountType: Cookies.get(USER_DATA_KEY.accountType),
            accountName: Cookies.get(USER_DATA_KEY.accountName)
        };
    } else {
        return null;
    }
}

export function getAllowRouter() {
    console.log('[auth.js]获取已授权路由');
    if (Cookies.get(USER_DATA_KEY.Token)) {
        return {
            allowRouter: JSON.parse(Cookies.get(USER_DATA_KEY.allowRouter))
        };
    } else {
        return null;
    }
}

export function setAccount(token, account_type, accountName, allowRouter) {
    Cookies.set(USER_DATA_KEY.Token, token, { expires: 7 });
    Cookies.set(USER_DATA_KEY.accountType, account_type, { expires: 7 });
    Cookies.set(USER_DATA_KEY.accountName, accountName, { expires: 7 });
    Cookies.set(USER_DATA_KEY.allowRouter, JSON.stringify(allowRouter), {
        expires: 7
    });
    console.log('[auth.js]成功记录用户信息');
    return token;
}

export function logoutAccount() {
    function clearCookie() {
        var keys = document.cookie.match(/[^ =;]+(?=)/g);
        if (keys) {
            for (var i = keys.length; i--; ) {
                document.cookie =
                    keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString(); //清除当前域名下的,例如：m.kevis.com
                document.cookie =
                    keys[i] +
                    '=0;path=/;domain=' +
                    document.domain +
                    ';expires=' +
                    new Date(0).toUTCString(); //清除当前域名下的，例如 .m.kevis.com
                document.cookie =
                    keys[i] +
                    '=0;path=/;domain=kevis.com;expires=' +
                    new Date(0).toUTCString(); //清除一级域名下的或指定的，例如 .kevis.com
            }
        }
        localStorage.clear();
        //加入该行后会清除缓存，当点击退出按钮后，会清除登录信息缓存
        window.sessionStorage.clear();
    }
    clearCookie(); // 清除Cookie缓存
    return null;
}
