<template>
    <el-scrollbar wrap-class="scrollbar-wrapper" style="padding-bottom: 100px">
        <el-menu
            :default-active="$route.path"
            :collapse="isCollapse"
            :background-color="variables.menuBg"
            :text-color="variables.menuText"
            :active-text-color="variables.menuActiveText"
            :collapse-transition="false"
            mode="vertical"
        >
            <sidebar-item
                v-for="(route, index) in routes"
                :key="index + route.path"
                :item="route"
                :base-path="route.path"
                :access-type="Number(userdata.accountType)"
                :allow-router="allow_router.allowRouter"
            />
        </el-menu>
    </el-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex';
import variables from '@/styles/variables.scss';
import SidebarItem from './SidebarItem';
import { getAccountData, getAllowRouter } from '@/utils/auth';

export default {
    components: { SidebarItem },
    props: {
        nowActiveSelect: {
            type: Number,
            default: 1
        }
    },
    created() {},
    watch: {
        nowActiveSelect(newItem) {
            console.log(newItem);
            return this.$router.options.routes.filter(
                route => route.meta.functype === newItem
            );
        }
    },
    data() {
        return {
            userdata: getAccountData(),
            allow_router: getAllowRouter()
        };
    },
    computed: {
        ...mapGetters(['sidebar']),
        routes() {
            return this.$router.options.routes.filter(
                route => route.meta.functype === this.nowActiveSelect
            );
        },
        isCollapse() {
            return !this.sidebar.opened;
        },
        variables() {
            return variables;
        }
    }
};
</script>

<style scoped>
@media screen and (max-width: 900px) {
}
</style>
