<template>
  <div class="layoutHeader">
    <div class="layoutHeaderWidthIimit">
      <!--logo和框架名-->
      <div class="contentFloatLeft">
        <div class="layoutLogo" @click="switchSiderBar()">
          <div class="logo">
            <img src="@/assets/layoutLogo.png" />
          </div>
        </div>
        <div class="layoutTitleLine">|</div>
        <div class="layoutTitle">{{ headerName[userdata.accountType] }}</div>
      </div>

      <!-- 用户登录栏 -->
      <div class="contentFloatRight">
        <el-dropdown class="loginStatusBar">
          <span class="el-dropdown-link">
            <span>欢迎你 {{ userdata.accountName }} </span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="logoutbutton()">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { logoutAccount, getAccountData } from "@/utils/auth";  // getuser


export default {
  name: "layoutHeader",
  components: {},
  data() {
    return {
      userdata: null,
      headerName: {
        1: "管理中心",
        2: "世泊车库云后台",
        3: "世泊商户中心",
      }
    };
  },
  watch: {},
  methods: {
    logoutbutton() {
      logoutAccount();
      this.$router.push('/login')
    },
    switchSiderBar() {
      if (window.innerWidth < 900) {
        console.log(localStorage.getItem("isSwitchSiderBar"));
        switch (Number(localStorage.getItem("isSwitchSiderBar"))) {
          case 1:
            localStorage.setItem("isSwitchSiderBar", 0);
            break;
          case 0:
            localStorage.setItem("isSwitchSiderBar", 1);
            break;
          default:
            console.log(localStorage.getItem("isSwitchSiderBar") === 1 )
        }
        this.$bus.$emit('isSwitchSiderBar', localStorage.getItem("isSwitchSiderBar"))
      }
    },
  },
  created() {
    this.userdata = getAccountData();
    localStorage.setItem("isSwitchSiderBar", 0);
  },
};
</script>

<style scoped>
@media screen and (max-width: 900px) {
  .layoutTitle {
    display: none;
  }
  .layoutHeader .loginStatusBar {
    float: right;
    line-height: 61px;
    color: #fff;
    cursor: pointer;
  }
}
</style>
