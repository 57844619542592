<template>
  <div>
    <el-page-header class="el-page-header" @back="goBack" :content="content_name"></el-page-header>
  </div>
</template>

<script>
  export default {
    name:"infoHeader",
    props: {
        content_name: String
    },
    methods: {
      goBack() {
        this.$router.back()
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .el-page-header{
        line-height: 90px;
    }
</style>