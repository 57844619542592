<template>
    <div class="layoutFooter">
            <!-- Footer -->
    </div>
</template>

<script>
    export default {
        name:'layoutFooter',
        methods: { //事件处理器
        },
        components: { //定义组件
        },
        created() { //生命周期函数
        }
    }
</script>
<style lang="scss" scoped>
</style>