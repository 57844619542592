// import Layout from '../layout/Layout.vue'
const commonMap = [
  {
    path: '/',
    name: 'welcome',
    component: () => import('@/views/home/Home'),
    children: [],
    meta: { title: '世泊云-欢迎', notNeedAuth: true }
  },
  {
    path: '/login',
    hidden: true,
    name: 'login',
    component: () => import('@/views/login'),
    meta: { title: '登录世泊云', notNeedAuth: true }
  },
  {
    path: '/wiki',
    name: 'wiki',
    component: () => import('@/views/abnormal/index'),
    children: [],
    meta: { title: '开放WIKI-错误码查询', notNeedAuth: true }
  },{
    path: '/404',
    name: '404',
    component: () => import('@/views/error-page/404.vue'),
    children: [],
    meta: { title: '404错误页面', notNeedAuth: true }
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/error-page/401.vue'),
    children: [],
    meta: { title: '401错误页面', notNeedAuth: true }
  }
]

export { commonMap }