import router from './router'
import {getAccountLoginStatus} from '@/utils/auth'

router.beforeEach((to, from, next) => {

    /* 路由发生变化修改页面title */
    if (to.meta.title) {
      document.title = to.meta.title + ' - 世泊通管理平台'
    }
    if (to.matched.some(record => !record.meta.notNeedAuth)) {
      let user = getAccountLoginStatus()
      if (user) {
        next()
      } else {
        console.log("登陆失效")
        router.replace({ name: "login", params: { msg: "登录失效" } })
      }
    } else {
      next()
    }
  })
// const whiteList = ['/login', '/','/manage/welcome']
// router.beforeEach((to, from, next) => {

//   /* 路由发生变化修改页面title */
//   if (to.meta.title) {
//     document.title = to.meta.title + ' - 世泊通管理平台'
//   }

//   if (to.path === '/login') {
//     console.log('1');
//     next();   // 注意next() , 会陷入无限回调
//     return
//   }
//   if (store.getters.token) { // 判断是否有token
//     console.log(store.getters.token,'token分支');
//     const hasRoles = store.getters.roles && store.getters.roles.length > 0
//     if (hasRoles) { // 判断当前用户是否已拉取完  user_info信息
//       console.log('2');
//       store.dispatch('loginByUsername',{
//         account: '123',
//         password: '123'
//       }).then(res => { // 拉取info
//         console.log(res,'33333333');
//         const roles = res.allow_router;
//         console.log(roles,'查看是否拿到路由信息');

//         store.dispatch('generateRoutes', { roles }).then(() => { // 生成可访问的路由表
//           router.addRoutes(store.getters.addRouters) // 动态添加可访问路由表
//           next({ ...to, replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true , 导航不会留下历史记
//         })
//       }).catch(err => {
//         console.log('3');
//         console.log(err);
//         next('/login'); 
//       });
//     } else {
//       console.log('4');
//       next() //当有用户权限的时候，说明所有可访问路由已生成 如访问没权限的全面会自动进入404页面
//     }
//   } else {
//     if (whiteList.indexOf(to.path) !== -1 ) { // 在免登录白名单，直接进入
//       console.log('5'); // 进入白名单路由
//       next();
//     } else {
//       next('/login'); // 否则全部重定向到登录页
//     }
    
//   }
// });