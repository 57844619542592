import Vue from 'vue'
import VueRouter from 'vue-router'
import { getAccountLoginStatus } from '@/utils/auth'

// 路由
Vue.use(VueRouter)

// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

import {commonMap} from './commonMap'   //基础通用页面 （无需权限）
import {createManageRouterList} from './manage/manageMainMap'   //管理员
import {createMerchantRouterList} from './merchant/merchantMainMap'   //商户
import {createPartnerRouterList} from './partner/partnerMainMap'    //合作方
// import {createBusinessRouterList} from './business/businessMainMap'


function createIndexRouter(){

  var routeslist = []
  let loginstatus = getAccountLoginStatus()
  if (loginstatus){
    console.log(JSON.stringify(routeslist),'路由清空完成');
    console.log(loginstatus.accountType,'用户类型');
    if (loginstatus.accountType == 1){
      console.log('进入管理员角色');
      return routeslist.concat(commonMap, createManageRouterList());    //管理员
    }else if (loginstatus.accountType == 2){
      console.log('进入合作伙伴角色');
      return routeslist.concat(commonMap, createPartnerRouterList());     //合作伙伴
    }else if (loginstatus.accountType == 3){
      return routeslist.concat(commonMap, createMerchantRouterList());   //商户
    }
  }else{
    return routeslist.concat(commonMap, createManageRouterList());    //基础导航
  }

}

const createRouter = () => new VueRouter({
  base: '',
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: createIndexRouter()
})

const router = createRouter();
// 在登录页进来的时候清空
// 点击登录的时候
// 重新调一次动态路由的添加方法createIndexRouter()


// 清空路由
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // 重置 router 的 matcher
}


export default router