import Layout from '../../layout/Layout.vue'


function createMerchantRouterList() {
  let routeslist = []
  return routeslist.concat(manageIndexMap);
}


const manageIndexMap = [
  {
    path: '/merchant',
    component: Layout,
    redirect: '/',
    meta: { notNeedAuth: true },
    children: [
      {
        path: '',
        component: () => import('@/views/merchant/Home'),
        name: 'merchant_home',
        meta: { title: '欢迎', icon: '', notNeedAuth: true }
      }
    ]
  }
]


export { createMerchantRouterList }