import Vue from 'vue'
// import ElementUI from 'element-ui'
import App from './App.vue'
import router from './router'
import store from './store'
import * as elementui from  '@/plugins/elementui.js'
// import {getAccountLoginStatus} from '@/utils/auth'
import '@/permission' // permission control
import '@/styles/main.scss' // global css
import '@/styles/scollbar.scss'

import 'element-ui/lib/theme-chalk/index.css'
// import bootstrap from '@/plugins/bootstrap.js'

// 引入全局组件
import infoHeader from '@/components/infoHeader';
import pageHeader from '@/components/pageHeader';
Vue.component(infoHeader.name, infoHeader)
Vue.component(pageHeader.name, pageHeader)

Vue.config.productionTip = false
// Vue.use(ElementUI)

Object.defineProperty(Vue.prototype, '$http', {
  value: function(requestPromise, successCallback) {
    requestPromise.then(res => {
      console.log("Load:",res)
      if (!res) return
      successCallback && successCallback(res)
    })
  }
})


Vue.prototype.$bus = new Vue({
  router,
  store,
  elementui,
  render: h => h(App)
}).$mount('#app')