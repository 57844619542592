import Layout from '../../layout/Layout.vue'

const financeManagement = [
    {
      path: '/manage/personnel_management/account',
      component: Layout,
      redirect: '/',
      meta: { icon: '', notNeedAuth: true, functype: 6},
      children: [
        {
            path: '',
            component: () => import('@/views/manage/personnelManagement/Account.vue'),
            name: 'personnelManagement_AccountManage',
            meta: { title: '停取车缴费记录', icon: '', notNeedAuth: true, functype: 6}
        }
      ]
    },
    {
        path: '/manage/personnel_management/jurisdiction',
        component: Layout,
        redirect: '/',
        meta: { icon: '', notNeedAuth: true, functype: 6},
        children: [
            {
                path: '',
                component: () => import('@/views/manage/personnelManagement/Jurisdiction.vue'),
                name: 'personnelManagement_JurisdictionManage',
                meta: { title: '月保缴费记录', icon: '', notNeedAuth: true, functype: 6}
            }
        ]
    },
    {
        path: '/manage/personnel_management/account_group',
        component: Layout,
        redirect: '/',
        meta: { notNeedAuth: true, functype: 6},
        children: [
            {
            path: '',
            component: () => import('@/views/manage/personnelManagement/AccountGroup.vue'),
            name: 'personnelManagement_AccountGroup',
            meta: { title: '退款记录', icon: '', notNeedAuth: true, functype: 6}
            }
        ]
    }
]

export { financeManagement }