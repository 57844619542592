import request from '@/utils/request'

export function Login(data) {
    return request({
        url: '/platform_api/auth_centre/login_v3',
        method: 'post',
        data: data
    })
}

//文件上传
export function upload(data) {
    return request({
        url: '/upload_api/file',
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}