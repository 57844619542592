import axios from 'axios';
import { Notification } from 'element-ui';
import { HttpRoot } from '@/api/config';
import router from '../router';
import { logoutAccount, getAccountLoginStatus } from './auth';

// 常量定义
const TIMEOUT = 50000;
const Exception_Code = 400;
const UNAUTHORIZED_CODE = 10086;
const SUCCESS_CODE = 200;
const NOTIFICATION_DURATION = 5000;
const SUCCESS_NOTIFICATION_DURATION = 800;

// 创建axios实例
const service = axios.create({
    baseURL: HttpRoot, // API 的 base_url
    timeout: TIMEOUT, // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
    config => {
        const Authorization = getAccountLoginStatus();
        console.log('[service.interceptors.request]', Authorization);
        if (Authorization) {
            config.headers['Authorization'] = Authorization.Token; // 让每个请求携带自定义token
        }
        return config;
    },
    error => {
        console.error('Request error:', error); // for debug
        return Promise.reject(error);
    }
);

// response拦截器
service.interceptors.response.use(
    response => {
        const res = response.data;
        console.log('[service.interceptors.response]', res);

        switch (res.code) {
            case Exception_Code:
                Notification({
                    message: res.msg,
                    type: 'error',
                    duration: NOTIFICATION_DURATION
                });
                break;
            case SUCCESS_CODE:
                Notification({
                    message: res.msg,
                    type: 'success',
                    duration: SUCCESS_NOTIFICATION_DURATION
                });
                break;
            case UNAUTHORIZED_CODE:
                Notification({
                    message: res.msg,
                    type: 'error',
                    duration: NOTIFICATION_DURATION
                });
                // logoutAccount();
                // setTimeout(() => {
                //     router.push({ name: 'login' });
                // }, 1500);
                router.push({name:'401'});
                break;
            default:
                Notification({
                    message: res.msg,
                    type: 'error',
                    duration: NOTIFICATION_DURATION
                });
                logoutAccount();
                console.warn('Unhandled response code:', res.code);
        }
        
        return res;
    },
    error => {
        console.error('Response error:', error); // for debug
        Notification({
            message: error.message,
            type: 'error',
            duration: NOTIFICATION_DURATION
        });
        return Promise.reject(error);
    }
);

export default service;
