// router.js

import Router from 'vue-router';

import Login from '@/views/login/';
import Layout from '../layout/Layout.vue'

// const home = resolve => require(['@/views/home/Home'], resolve);

// ddd 修改-尝试重构路由表 - 未上线使用
//所有权限通用路由表 
//如首页和登录页和一些不用权限的公用页面
export const constantRouterMap = [
    {
      path: '/wiki',
      component: () => import('@/views/abnormal/index'),
      name: 'wiki',
      meta:{title:'开放WIKI-错误码查询'}
    },
    { path: '/login', component: Login , name:'login' },
    {
        path: '/',
        component: () => import('@/views/home/Home'),
        name: 'home',
        meta:{title:'首页'}
    }
]

//实例化vue的时候只挂载constantRouter
export default new Router({
    mode: 'history',
    routes: constantRouterMap
});
  
//异步挂载的路由
//动态需要根据权限加载的路由表 
export const asyncRouterMap = [
    {
      path: '/manage/welcome',
      component: Layout,
      name: '权限测试',
      meta: { role: ['admin','manage'] }, // 页面需要的权限
      children: [
      { 
        path: '',
        component: () => import('@/views/manage/Home'),
        name: 'manage_welcome',
        meta: { role: ['admin','partner'] }  // 页面需要的权限
      }]
    },
    // 404 页面一定要最后加载
    { path: '*', redirect: '/404', hidden: true } 
];