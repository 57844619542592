/**
 * 普通输入框必填的校验
 */
export const checkRqInput = {
  required: true,
  message: '请填写此字段',
  trigger: 'blur'
}
/**
 * 上传附件必填的校验
 */
export const checkRqFile = {
  required: true,
  message: '请选择文件',
  trigger: 'change'
}
/**
 * 普通下拉选择必填的校验
 */
export const checkRqSelect = {
  required: true,
  message: '请在列表中选择一项',
  trigger: 'change'
}
/**
 * 普通下拉选择必填的校验
 */
export const checkRqMulSelect = {
  type: 'array',
  message: '请在列表中选择一项',
  trigger: 'change',
  validator: function(rule, value, callback) {
    if (value.length) {
      callback()
    } else {
      callback(new Error('请选择至少一项'))
    }
  }
}
/**
 * 密码框必须大于6位的校验
 */
export const checkPwdLength = {
  min: 6,
  message: '长度为6个字符以上',
  trigger: 'blur'
}
/**
 * 手机号码格式的校验
 */
export const checkTel = {
  trigger: 'blur',
  validator: function(rule, value, callback) {
    if (/^1[3-9]\d{9}$/.test(value) || value === '') {
      callback()
    } else {
      callback(new Error('请输入正确的手机号码'))
    }
  }
}
/**
 *  中文的校验
 */
export const checkChinese = {
  trigger: 'blur',
  validator: function(rule, value, callback) {
    if (/^[\u4E00-\u9FA5]+$/.test(value) || value === '') {
      callback()
    } else {
      callback(new Error('请输入正确的汉字'))
    }
  }
}
/**
 * 邮箱的校验
 */
export const checkEmail = {
  trigger: 'blur',
  validator: function(rule, value, callback) {
    if (/^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(value) || value === '') {
      callback()
    } else {
      callback(new Error('请输入正确的邮箱'))
    }
  }
}
/**
 * 传真的校验
 */
export const checkFax = {
  trigger: 'blur',
  validator: function(rule, value, callback) {
    if (/^(\d{3,4}-)?\d{7,8}$/.test(value) || value === '') {
      callback()
    } else {
      callback(new Error('请输入正确的传真'))
    }
  }
}
/**
 * qq号的校验
 */
export const checkQQ = {
  trigger: 'blur',
  validator: function(rule, value, callback) {
    if (/^[1-9]\d{4,11}/.test(value) || value === '') {
      callback()
    } else {
      callback(new Error('请输入正确的QQ'))
    }
  }
}
/**
 * 日期必填
 */
export const checkRqDate = {
  required: true,
  message: '请填写此字段',
  trigger: 'change'
}
/**
 * 类型是数组的校验
 */
export const checkArray = {
  type: 'array',
  message: '该字段是数组',
  trigger: 'change'
}

// export function isvalidUsername(str) {
//   const valid_map = ['admin', 'editor']
//   return valid_map.indexOf(str.trim()) >= 0
// }
// 
// 路径校验
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}




/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  const reg = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}
