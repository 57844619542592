<template>
    <div>
        <sidebar
            class="sidebar"
            v-if="activeIndex != 1"
            :nowActiveSelect="Number(activeIndex)"
        />
        <!-- 头部分页栏 -->
        <div class="contentRadioButton" v-if="userdata.accountType == 1">
            <el-radio-group v-model="activeIndex" @input="handleSelect">
                <el-radio-button label="1">我的事项</el-radio-button>
                <el-radio-button label="2">业务</el-radio-button>
                <el-radio-button label="3">运维</el-radio-button>
                <el-radio-button label="4">客服</el-radio-button>
                <el-radio-button label="5">人事</el-radio-button>
                <el-radio-button label="6">财务</el-radio-button>
            </el-radio-group>
        </div>

        <div class="contentRadioButton" v-if="userdata.accountType == 2">
            <el-radio-group v-model="activeIndex" @input="handleSelect">
                <el-radio-button label="1">车库大屏</el-radio-button>
                <el-radio-button label="2">车库管理</el-radio-button>
            </el-radio-group>
        </div>

        <layout-header />

        <div class="content" @click="collpse" v-if="activeIndex != 1">
            <layout-appmain :bigWindows="bigWindowstype" />
            <layout-footer />
        </div>

        <div
            class="content"
            style="width: 100%; margin-left: 0px"
            @click="collpse"
            v-if="activeIndex == 1"
        >
            <layout-appmain :bigWindows="bigWindowstype" />
            <layout-footer />
        </div>
    </div>
</template>

<script>
import { getAccountData } from '@/utils/auth'; // getuser
import Sidebar from './components/Sidebar';
import header from './components/Header.vue';
import footer from './components/Footer.vue';
import appmain from './AppMain';
export default {
    name: 'Home',
    data() {
        return {
            userdata: getAccountData(),
            activeIndex: 1,
            bigWindowstype: false
        };
    },
    created() {
        this.activeIndex = localStorage.getItem('activeIndex');
    },
    methods: {
        handleSelect(item) {
            this.activeIndex = item;
            localStorage.setItem('activeIndex', item);
        },
        //事件处理器
        hasClass(ele, cls) {
            return !!ele.className.match(
                new RegExp('(\\s|^)' + cls + '(\\s|$)')
            );
        },
        collpse() {
            console.log('数据');
            if (window.innerWidth < 900) {
                //关闭
                if (
                    this.hasClass(document.querySelector('.sidebar'), 'active')
                ) {
                    document
                        .querySelector('.sidebar')
                        .classList.toggle('active');
                }
                document
                    .querySelector('.sidebar')
                    .classList.toggle('non_active');
            }
        }
    },
    components: {
        //定义组件
        'layout-header': header,
        'layout-footer': footer,
        'layout-appmain': appmain,
        Sidebar
    },
    mounted() {
        this.$bus.$on('isSwitchSiderBar', data => {
            console.log('我是xxxxxxxxxxxx组件，收到数据了', data);
            if (Number(localStorage.getItem('isSwitchSiderBar')) == 1) {
                if (
                    this.hasClass(
                        document.querySelector('.sidebar'),
                        'non_active'
                    )
                ) {
                    document
                        .querySelector('.sidebar')
                        .classList.toggle('non_active');
                }
                document.querySelector('.sidebar').classList.toggle('active');
            } else {
                if (
                    this.hasClass(document.querySelector('.sidebar'), 'active')
                ) {
                    document
                        .querySelector('.sidebar')
                        .classList.toggle('active');
                }
                document
                    .querySelector('.sidebar')
                    .classList.toggle('non_active');
            }

            //   document.querySelector(".sidebar").classList.toggle('non_active')
        });
    }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.sidebar {
    top: 0;
    position: fixed;
    width: 230px;
    background-color: rgb(91, 140, 255);
    height: 100vh;
}
.content {
    // position: relative;
    overflow: hidden;
    width: calc(100% - 230px);
    margin-left: 230px;
}
@media screen and (max-width: 900px) {
    .sidebar {
        display: block !important;
        height: 100%;
        float: left;
        z-index: 999;
        width: 180px;
        margin-left: -420px;
    }
    .content {
        margin-left: unset;
        width: unset;
    }

    @keyframes active {
        from {
            opacity: 0;
            // margin-left:-140px;
            // position: absolute;
            // -webkit-transform: translateX(160px);
            // transform: translateX(160px);
            display: block !important;
            height: 100%;
            float: left;
            z-index: 999;
            width: 180px;
            margin-left: -200px;
        }
        to {
            opacity: 1;
            // -webkit-transform: translateX(160px);
            // transform: translateX(160px);
            display: block !important;
            height: 100%;
            float: left;
            z-index: 999;
            width: 180px;
            margin-left: -0px;
        }
    }
    .active {
        -webkit-animation-name: active;
        animation-name: active;
        -webkit-animation-duration: 1s;
        animation-duration: 0.5s;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }
    @keyframes non_active {
        from {
            opacity: 1;
            // -webkit-transform: translateX(160px);
            // transform: translateX(160px);
            display: block !important;
            height: 100%;
            float: left;
            z-index: 999;
            width: 180px;
            margin-left: -0px;
        }
        to {
            opacity: 0;
            // margin-left:-140px;
            // position: absolute;
            // -webkit-transform: translateX(160px);
            // transform: translateX(160px);
            display: block !important;
            height: 100%;
            float: left;
            z-index: 999;
            width: 180px;
            margin-left: -200px;
        }
    }
    .non_active {
        -webkit-animation-name: non_active;
        animation-name: non_active;
        -webkit-animation-duration: 1s;
        animation-duration: 1ds;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }
}
.contentRadioButton {
    z-index: 999;
    /* position: absolute;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  display: inline-block;
  vertical-align: middle;
  width: 540px; */
    position: fixed;
    width: 60%;
    left: 0;
    right: 0;
    margin: 0 auto;
    // margin-top: 10px;
    min-width: 600px;
    display: flex;
    justify-content: center;
}
/deep/ .contentRadioButton .el-radio-button__inner {
    border-color: #00000000;
    background-color: #00000000;
    color: #ffffff;
    border-radius: 6px;
    padding: 17px 30px;
    border: none !important;
    border-left: 0px;
}
/deep/ .contentRadioButton .el-radio-group {
    border-color: rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
    border-radius: 10px;
    /* padding: 8px 20px; */
    border: none !important;
    border-left: 0px;
    // border: 1px
    //   solid
    //   #a7a7a7;
}

/deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    border-color: rgba(255, 255, 255, 0);
    background-color: rgba(255, 255, 255, 0);
    color: #66b1ff;

    border-radius: 6px;
    padding: 17px 30px;
    // border: none !important;
    border-left: 0px;
    box-shadow: none;
}

/deep/ .contentRadioButton .is-active {
    border-left: 0px;
    // border: 1px
    //   solid
    //   #a7a7a7;
    border-color: rgba(255, 255, 255, 0);
    background-color: rgba(255, 255, 255, 0);
    border-radius: 0px;
    // border: none !important;
    border-top: 4px solid #66b1ff;
    box-shadow: none;
    border-left: 0px;
}
</style>
