import Layout from '../../layout/Layout.vue'

const custservManagement = [
    {
      path: '/manage/personnel_management/account',
      component: Layout,
      redirect: '/',
      meta: { title: '我的接待信息', icon: '', notNeedAuth: true, functype: 4},
      children: [
        {
            path: '',
            component: () => import('@/views/manage/personnelManagement/Account.vue'),
            name: 'personnelManagement_AccountManage',
            meta: { title: '我的接待信息', icon: '', notNeedAuth: true, functype: 4}
        }
      ]
    },
    {
        path: '/manage/personnel_management/jurisdiction',
        component: Layout,
        redirect: '/',
        meta: { title: '待处理消息', icon: '', notNeedAuth: true, functype: 4},
        children: [
            {
                path: '',
                component: () => import('@/views/manage/personnelManagement/Jurisdiction.vue'),
                name: 'personnelManagement_JurisdictionManage',
                meta: { title: '待处理消息', icon: '', notNeedAuth: true, functype: 4}
            }
        ]
    },
    {
        path: '/manage/personnel_management/account_group',
        component: Layout,
        redirect: '/',
        meta: { notNeedAuth: true, functype: 4},
        children: [
            {
            path: '',
            component: () => import('@/views/manage/personnelManagement/AccountGroup.vue'),
            name: 'personnelManagement_AccountGroup',
            meta: { title: '处理记录', icon: '', notNeedAuth: true, functype: 4}
            }
        ]
    },
    {
        path: '/manage/personnel_management/account_group',
        component: Layout,
        redirect: '/',
        meta: { notNeedAuth: true, functype: 4},
        children: [
            {
            path: '',
            component: () => import('@/views/manage/personnelManagement/AccountGroup.vue'),
            name: 'personnelManagement_AccountGroup',
            meta: { title: '我的评价', icon: '', notNeedAuth: true, functype: 4}
            }
        ]
    }

]

export { custservManagement }