import Layout from '../../layout/Layout.vue'

import {personnelManagement} from './personnelManagement'
import {businessManagement} from './businessManagement'
import {operationManagement} from './operationManagement'
import {custservManagement} from './custservManagement'
import {financeManagement} from './financeManagement'
function createManageRouterList() {
  let routeslist = []
  return routeslist.concat(manageIndexMap, personnelManagement, businessManagement,operationManagement,custservManagement,financeManagement);
}


const manageIndexMap = [
  {
    path: '/manage/welcome',
    component: Layout,
    meta: { notNeedAuth: true,functype: 1},
    children: [
      {
        path: '',
        component: () => import('@/views/manage/Home'),
        name: 'manage_welcome',
        meta: { title: '欢迎', icon: '', notNeedAuth: true, functype: 1}
      }
    ]
  }
]

export { createManageRouterList }