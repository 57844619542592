import Layout from '../../layout/Layout.vue'

const personnelManagement = [
    {
      path: '/manage/personnel_management/account',
      component: Layout,
      redirect: '/',
      meta: { title: '账户管理', icon: '', notNeedAuth: true, functype: 5},
      children: [
        {
            path: '',
            component: () => import('@/views/manage/personnelManagement/Account.vue'),
            name: 'personnelManagement_AccountManage',
            meta: { title: '查询账户', icon: '', notNeedAuth: true, functype: 5}
        },
        {
            path: 'create',
            component: () => import('@/views/manage/personnelManagement/AccountCreate.vue'),
            name: 'personnelManagement_AccountManage_Create',
            meta: { title: '新增账户', icon: '', notNeedAuth: true, functype: 5}
        },
        {
            path: 'info',
            hidden: true,
            component: () => import('@/views/manage/personnelManagement/AccountInfo.vue'),
            name: 'personnelManagement_AccountManage_Info',
            meta: { title: '账户详细信息', icon: '', notNeedAuth: true, functype: 5}
        }
      ]
    },
    {
        path: '/manage/personnel_management/jurisdiction',
        component: Layout,
        redirect: '/',
        meta: { title: '权限组管理', icon: '', notNeedAuth: true, functype: 5},
        children: [
            {
                path: '',
                component: () => import('@/views/manage/personnelManagement/Jurisdiction.vue'),
                name: 'personnelManagement_JurisdictionManage',
                meta: { title: '所有权限组', icon: '', notNeedAuth: true, functype: 5}
            },
            {
                path: 'create',
                component: () => import('@/views/manage/personnelManagement/JurisdictionCreate.vue'),
                name: 'personnelManagement_JurisdictionManage_Create',
                meta: { title: '新增权限组', icon: '', notNeedAuth: true, functype: 5}
            },
            {
                path: 'info',
                hidden: true,
                component: () => import('@/views/manage/personnelManagement/JurisdictionInfo.vue'),
                name: 'personnelManagement_JurisdictionManage_Info',
                meta: { title: '权限组详情', icon: '', notNeedAuth: true, functype: 5}
            }
        ]
    },
    {
        path: '/manage/personnel_management/account_group',
        component: Layout,
        redirect: '/',
        meta: { notNeedAuth: true, functype: 5},
        children: [
            {
            path: '',
            component: () => import('@/views/manage/personnelManagement/AccountGroup.vue'),
            name: 'personnelManagement_AccountGroup',
            meta: { title: '用户组管理', icon: '', notNeedAuth: true, functype: 5}
            }
        ]
    }
]

export { personnelManagement }