<template>
  <div :class="line ? 'pageHeader_line':'pageHeader'">
      {{pagename}}
        <span v-if="total">
          共有 {{ total }} 条数据
        </span>
  </div>
</template>

<script>
  export default {
    name:"pageHeader",
    props: {
      pagename: {
        type: String,
        default: ""
      },
      total: {
        type: Number,
        default: 0
      },
      line: {
        type: Boolean,
        default: false
      },
    },
    methods: {
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  .pageHeader_line{
    float: left;
      line-height: 90px;font-size: 18px;font-weight: 600;
      span{
          margin-left: 12px;
          font-size: 14px;
          color:gray
      }
  }
  .pageHeader{
      line-height: 90px;font-size: 18px;font-weight: 600;
      span{
          margin-left: 12px;
          font-size: 14px;
          color:gray
      }
  }
</style>