import Layout from '../../layout/Layout.vue'

// functype —— 控制侧边导航栏显示
const businessManagement = [
    {
        path: '/business/park_records',
        component: Layout,
        redirect: '/',
        meta: { title: '停车业务管理', icon: '', notNeedAuth: true, functype: 2},
        children: [
            {
                path: '',
                component: () => import('@/views/manage/business/parkingRecordManage/parkingRecordManage.vue'),
                name: 'garage_Manage',
                meta: { title: '停车业务管理', icon: '', notNeedAuth: true, functype: 2}
            },
            {
                path: 'info',
                hidden:true,
                component: () => import('@/views/manage/business/parkingRecordManage/parkingRecordInfo.vue'),
                name: 'parkRecords_Info',
                meta: { title: '停车业务详情', icon: '', notNeedAuth: true, functype: 2}
            }
          ],
    },
    {
        path: '/business/userMini_managent',
        component: Layout,
        redirect: '/',
        meta: { title: '小程序用户管理', icon: '', notNeedAuth: true, functype: 2},
        children: [
            {
                path: '',
                component: () => import('@/views/manage/business/usermanage/userManage.vue'),
                name: 'userMini_Manage',
                meta: { title: '小程序用户管理', icon: '', notNeedAuth: true, functype: 2}
            }
          ],
    },
    {
      path: '/business/garage_management',
      component: Layout,
      redirect: '/',
      meta: { title: '车库管理', icon: '', notNeedAuth: true, functype: 2},
      children: [
        {
            path: '',
            component: () => import('@/views/manage/business/garage/garageManage.vue'),
            name: 'garage_Manage',
            meta: { title: '查询车库信息', icon: '', notNeedAuth: true, functype: 2}
        },
        {
            path: 'create',
            component: () => import('@/views/manage/business/garage/garageCreate.vue'),
            name: 'garage_Manage_Create',
            meta: { title: '新增车库单位', icon: '', notNeedAuth: true, functype: 2}
        },
        {
            path: 'info',
            hidden:true,
            component: () => import('@/views/manage/business/garage/garageManageInfo.vue'),
            name: 'garage_Manage_Info',
            meta: { title: '车库单位详情', icon: '', notNeedAuth: true, functype: 2}
        }
      ]
    },
    {
        path: '/business/partner_management',
        component: Layout,
        redirect: '/',
        meta: { title: '合作方管理', icon: '', notNeedAuth: true, functype: 2},
        children: [
            {
                path: '',
                component: () => import('@/views/manage/business/partherAccountManage/partnerAccountManage.vue'),
                name: 'partner_Manage',
                meta: { title: '所有合作方', icon: '', notNeedAuth: true, functype: 2}
            },
            {
                path: 'create',
                component: () => import('@/views/manage/business/partherAccountManage/partnerAccountCreate.vue'),
                name: 'partner_Manage_Create',
                meta: { title: '新增合作方', icon: '', notNeedAuth: true, functype: 2}
            },
            {
                path: 'info',
                hidden:true,
                component: () => import('@/views/manage/business/partherAccountManage/partnerEditAccount.vue'),
                name: 'partner_Manage_Info',
                meta: { title: '合作方详情', icon: '', notNeedAuth: true, functype: 2}
            }
        ]
    },
    {
        path: '/business/bigdata_management',
        component: Layout,
        redirect: '/',
        meta: { title: '大屏授权管理', icon: '', notNeedAuth: true, functype: 2},
        children: [
            {
                path: '',
                component: () => import('@/views/manage/business/bigdata/AppMain.vue'),
                name: 'bigdata_Manage',
                meta: { title: '数据大屏账户', icon: '', notNeedAuth: true, functype: 2}
            },
            {
                path: 'create',
                component: () => import('@/views/manage/business/bigdata/AppMain.vue'),
                name: 'bigdata_Manage_Create',
                meta: { title: '新增账户', icon: '', notNeedAuth: true, functype: 2}
            }
        ]
    },
    {
        path: '/business/monthlypass_management',
        component: Layout,
        redirect: '/',
        meta: { title: '月保管理', icon: '',notNeedAuth: true, functype: 2},
        children: [
            {
            path: '',
            component: () => import('@/views/manage/business/monthlypass/monthlyPassManage.vue'),
            name: 'monthlypass_Manage',
            meta: { title: '月保查询', icon: '', notNeedAuth: true, functype: 2}
            },
            {
                path: 'blacklist',
                component: () => import('@/views/manage/personnelManagement/AccountGroup.vue'),
                name: 'monthlypass_Blacklist',
                meta: { title: '月保黑名单', icon: '', notNeedAuth: true, functype: 2}
            }
        ]
    },
    {
        path: '/business/coupon_management',
        component: Layout,
        redirect: '/',
        meta: { title: '优惠券管理', icon: '',notNeedAuth: true, functype: 2},
        children: [
            {
            path: '',
            component: () => import('@/views/manage/business/coupon/couponManagement.vue'),
            name: 'coupon_Manage',
            meta: { title: '所有优惠券', icon: '', notNeedAuth: true, functype: 2}
            },
            {
                path: 'lock',
                component: () => import('@/views/manage/personnelManagement/AccountGroup.vue'),
                name: 'coupon_Lock',
                meta: { title: '被平台锁定优惠券', icon: '', notNeedAuth: true, functype: 2}
            }
        ]
    }
]

export { businessManagement }