import Layout from '../../layout/Layout.vue'

import { garagePartner } from './garagePartner' //合作方车库管理


function createPartnerRouterList() {
  let routeslist = []
  return routeslist.concat(partnerIndexMap,garagePartner);
}

const partnerIndexMap = [
  {
    path: '/partner/welcome',
    component: Layout,
    redirect: '/',
    meta: { notNeedAuth: true },
    children: [
      {
        path: '',
        component: () => import('@/views/partner/Home.vue'),
        name: 'partner_welcome',
        meta: { title: '欢迎', icon: '', notNeedAuth: true }
      }
    ]
  }
]


export { createPartnerRouterList }