import Layout from '../../layout/Layout.vue';

// functype —— 控制侧边导航栏显示
const garagePartner = [
    {
        path: '/garagemanage/park_records',
        component: Layout,
        redirect: '/',
        meta: {
            title: '车库业务管理',
            icon: '',
            notNeedAuth: true,
            functype: 2
        },
        children: [
            {
                path: '',
                component: () =>
                    import(
                        '@/views/partner/garageManage/parkingRecordManage/parkingRecordManage.vue'
                    ),
                name: 'garage_Manage',
                meta: {
                    title: '停车业务管理',
                    icon: '',
                    notNeedAuth: true,
                    functype: 2
                }
            },
            {
                path: 'info',
                hidden: true,
                component: () =>
                    import(
                        '@/views/partner/garageManage/parkingRecordManage/parkingRecordInfo.vue'
                    ),
                name: 'parkRecords_Info',
                meta: {
                    title: '停车业务详情',
                    icon: '',
                    notNeedAuth: true,
                    functype: 2
                }
            }
        ]
    },
    {
        path: '/operation/workOrder',
        redirect: '/',
        component: Layout,
        meta: { title: '工单管理', icon: '', notNeedAuth: true, functype: 2 },
        children: [
            {
                path: 'hall',
                component: () =>
                    import(
                        '@/views/partner/garageManage/workOrderHall/workOrderAll.vue'
                    ),
                name: 'work_Hall',
                meta: {
                    title: '工单大厅',
                    icon: '',
                    notNeedAuth: true,
                    functype: 2
                }
            },
            {
                path: 'mywork',
                component: () =>
                    import(
                        '@/views/partner/garageManage/workOrderHall/workOrderDetail.vue'
                    ),
                name: 'work_My',
                meta: {
                    title: '我的工单',
                    icon: '',
                    notNeedAuth: true,
                    functype: 2
                }
            },
            // {
            //     path: 'record',
            //     component: () =>
            //         import(
            //             '@/views/manage/personnelManagement/AccountGroup.vue'
            //         ),
            //     name: 'work_Record',
            //     meta: {
            //         title: '处理记录',
            //         icon: '',
            //         notNeedAuth: true,
            //         functype: 2
            //     }
            // },
            {
                path: 'detail',
                hidden: true,
                component: () =>
                    import(
                        '@/views/partner/garageManage/workOrderHall/workOrderDetail.vue'
                    ),
                name: 'work_Info',
                meta: {
                    title: '工单详情',
                    icon: '',
                    notNeedAuth: true,
                    functype: 2
                }
            },
            {
                path: 'processing',
                hidden: true,
                component: () =>
                    import(
                        '@/views/partner/garageManage/workOrderHall/workOrderProcessing.vue'
                    ),
                name: 'work_Processing',
                meta: {
                    title: '工单详情',
                    icon: '',
                    notNeedAuth: true,
                    functype: 2
                }
            },
            {
                path: 'create',
                hidden: true,
                component: () =>
                    import(
                        '@/views/partner/garageManage/workOrderHall/workOrderCreate.vue'
                    ),
                name: 'work_Create',
                meta: {
                    title: '创建工单',
                    icon: '',
                    notNeedAuth: true,
                    functype: 2
                }
            }
        ]
    }
];
export { garagePartner };
