<template>
    <div v-if="!item.hidden" class="menu-wrapper">
        <!-- && !item.alwaysShow -->

        <template
            v-if="
                hasOneShowingChild(item.children, item) &&
                (!onlyOneChild.children || onlyOneChild.noShowingChildren)
            "
        >
            <app-link
                :target="item.target"
                :to="resolvePath(onlyOneChild.path)"
            >
                <el-menu-item
                    :index="resolvePath(onlyOneChild.path)"
                    :class="{ 'submenu-title-noDropdown': !isNest }"
                >
                    <item
                        :meta="Object.assign({}, item.meta, onlyOneChild.meta)"
                    />
                </el-menu-item>
            </app-link>
        </template>

        <template v-else>
            <el-submenu
                ref="subMenu"
                :index="resolvePath(item.path)"
                popper-append-to-body
            >
                <template slot="title">
                    <item :meta="item.meta" />
                </template>
                <sidebar-item
                    v-for="(child, index) in returnChildrenList(accessType)"
                    :is-nest="true"
                    :item="child"
                    :key="index + child.path"
                    :base-path="resolvePath(child.path)"
                    class="nest-menu"
                />
            </el-submenu>
        </template>
    </div>
</template>

<script>
import path from 'path';
import { isExternal } from '@/utils/validate';
import Item from './Item';
import AppLink from './Link';

export default {
    name: 'SidebarItem',
    // eslint-disable-next-line vue/no-unused-components
    components: { Item, AppLink },
    props: {
        // route object
        item: {
            type: Object,
            required: true
        },
        isNest: {
            type: Boolean,
            default: false
        },
        basePath: {
            type: String,
            default: ''
        },
        accessType: {
            type: Number,
            default: null
        },
        allowRouter: {
            type: Array,
            default: () => []
        }
    },
    created() {
        // console.log("allowRouter", this.allowRouter)
    },
    data() {
        // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
        // TODO: refactor with render function
        this.onlyOneChild = null;
        return {};
    },
    methods: {
        returnChildrenList(accessType) {
            // var allowRouterT = this.allowRouter
            accessType;

            return this.item.children.filter(function (data) {
                return data;
            });
        },
        hasOneShowingChild(children = [], parent) {
            const showingChildren = children.filter(item => {
                if (item.hidden) {
                    return false;
                } else {
                    // Temp set(will be used if only has one showing child)
                    this.onlyOneChild = item;
                    return true;
                }
            });

            // When there is only one child router, the child router is displayed by default
            if (showingChildren.length === 1) {
                return true;
            }

            // Show parent if there are no child router to display
            if (showingChildren.length === 0) {
                this.onlyOneChild = {
                    ...parent,
                    path: '',
                    noShowingChildren: true
                };
                return true;
            }

            return false;
        },
        resolvePath(routePath) {
            if (isExternal(routePath)) {
                return routePath;
            }
            return path.resolve(this.basePath, routePath);
        }
    }
};
</script>
<style scoped>
.center {
    text-align: center;
}
</style>
