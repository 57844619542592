<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    meta: {
      type: Object,
      default: () => {
        return {
          title: '',
          icon: ''
        }
      }
    }
  },
  render(h, context) {
    const { icon, title } = context.props.meta
    const vnodes = []

    if (icon) {
      vnodes.push(<i class={icon}></i>)
    }

    if (title) {
      vnodes.push(<span slot="title" style="text-align:center">{(title)}</span>)
    }
    return vnodes
  }
}
</script>
