import Cookies from 'js-cookie'
import { Login } from "@/api/common";
import { getToken } from '@/utils/auth'

const user = {
    state: {
      token: getToken(),
      roles: [],
      device: 'desktop'
    },
    mutations: {
      SET_TOKEN: (state, token)=>{
        state.token = token
      },
      SET_ROLES: (state, roles) => {
        state.roles = roles
      }
    },
    actions: {
        loginByUsername({ commit }, userInfo) {
            console.log(userInfo,'userinfo信息');
            // const username = userInfo.account.trim()
            return new Promise((resolve, reject) => {

            Login(userInfo).then(response => {
                const data = response.data
                console.log(data.token);
                Cookies.set('Token', response.data.token)  // 登录成功后将token存储在cookie之中
                commit('SET_TOKEN', data.token)
                commit('SET_ROLES', data.allow_router)
                resolve(data)
                
            }).catch(error => {
                reject(error)
            });
            });
        },
        // get user info
        // getInfo({ commit, state }) {
        //     return new Promise((resolve, reject) => {
            
        //     // 缺少token接口，现在默认用Login接口拿到路由表
        //     getInfo(state.token).then(response => {
        //         const { data } = response

        //         if (!data) {
        //         reject('Verification failed, please Login again.')
        //         }

        //         const { roles, name } = data

        //         // roles must be a non-empty array
        //         if (!roles || roles.length <= 0) {
        //         reject('getInfo: roles must be a non-null array!')
        //         }

        //         commit('SET_ROLES', roles)
        //         commit('SET_NAME', name)
        //         resolve(data)
        //     }).catch(error => {
        //         reject(error)
        //     })
        //     })
        // },

    }
  }
  
  export default user