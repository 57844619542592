<template>
    <div class="content">
        <div class="l-content" :style="backgroundDiv">
            <div class="limit-l">
                <div class="limit-box">
                    <div class="logo">
                        <img src="@/assets/views/login/logo.png" alt="Logo" />
                    </div>

                    <div class="m1">
                        <img src="@/assets/views/login/m1.png" alt="m1" />
                    </div>

                    <div class="footer">
                        <div class="l">
                            © 2024 广东世泊智能装备科技有限公司 - ShiBoTech. All
                            rights reserved.
                        </div>
                        <div class="r">粤ICP备18124638号-1</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="r-content">
            <div class="limit-box">
                <div class="title1">登录你的</div>
                <div class="title2">世泊车库云账户</div>

                <div class="input-li">
                    <div class="input-title">手机号 或 邮箱</div>
                    <el-input
                        v-model="account"
                        class="input-body"
                        placeholder="phone or email"
                        prefix-icon="el-icon-user"
                    ></el-input>
                </div>

                <div class="input-li">
                    <div class="input-title">密码</div>
                    <el-input
                        v-model="password"
                        class="input-body"
                        type="password"
                        placeholder="input your password"
                        prefix-icon="el-icon-key"
                        show-password
                    ></el-input>
                </div>

                <div class="input-li">
                    <div class="input-title">遇到问题?</div>
                </div>

                <div class="input-li" v-if="show_alert">
                    <el-alert :title="error_msg" :closable="false" type="error">
                    </el-alert>
                </div>

                <el-button
                    type="primary"
                    class="submit"
                    align="center"
                    @click="submit"
                    >立即登录</el-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import { Login } from '@/api/common';
import { resetRouter } from '@/router';
import {
    getAccountLoginStatus,
    setAccount,
    getAccountData
} from '@/utils/auth';

export default {
    name: 'login',
    data() {
        return {
            error_msg: '',
            show_alert: false,
            account: '',
            password: '',
            otherQuery: {}
        };
    },
    computed: {
        backgroundDiv() {
            return {
                backgroundImage:
                    'url(' +
                    require('@/assets/views/login/background.png') +
                    ')'
            };
        }
    },
    created() {
        const user = getAccountLoginStatus();
        if (user) {
            const userdata = getAccountData();
            const routes = {
                1: 'manage_welcome',
                2: 'partner_welcome',
                3: 'merchant_home'
            };
            this.$router.push({ name: routes[userdata.accountType] });
        }
    },
    methods: {
        register() {
            this.$router.push({ name: 'register' });
        },
        checkInput() {
            if (!this.password) {
                this.$message({
                    message: '密码不能为空',
                    type: 'error'
                });
                return false;
            }
            return true;
        },
        async submit() {
            this.show_alert = false;
            if (!this.checkInput()) {
                return console.log('终止');
            }

            this.$message({
                message: '正在登录中',
                type: 'success'
            });

            try {
                const res = await Login({
                    account: this.account,
                    password: this.password
                });

                if (res.code === 200) {
                    setAccount(
                        res.data.token,
                        res.data.account_type,
                        res.data.name,
                        res.data.allow_router
                    );
                    console.log(res,'res');
                    this.$message({
                        message: '登录成功',
                        type: 'success'
                    });

                    resetRouter();
                    localStorage.setItem('activeIndex', 1);
                    this.$router.go(0);
                } else {
                    this.show_alert = true;
                    this.error_msg = res.msg;
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        duration: 5000
                    });
                }
            } catch (error) {
                this.show_alert = true;
                this.error_msg = error.message || '登录失败';
                this.$message({
                    message: this.error_msg,
                    type: 'error',
                    duration: 5000
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.el-divider__text {
    background-color: #fbfbfb;
    color: #9e9e9e;
}
.content {
    height: 100vh;
    .l-content {
        height: 100vh;
        width: 100%;
        background-color: #000000;
        display: flow-root;
        .limit-l {
            float: left;
            width: calc(100% - 550px);
        }
        .limit-box {
            position: relative;
            width: 80%;
            max-width: 1130px;
            margin: 0 auto;
            position: relative;
            height: calc(100vh - 160px);
            margin-top: 80px;
        }
        .logo {
            width: 100%;
        }
        .m1 {
            position: absolute;
            width: 65%;
            min-width: 55%;
            height: 65%;
            min-height: 55%;
            margin: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            img {
                margin: 0 auto;
                height: 100%;
            }
        }
        .footer {
            width: 100%;
            position: absolute;
            bottom: 0;
            color: #ffffff;
            font-size: 14px;
            .l {
                float: left;
            }
            .r {
                float: right;
            }
        }
    }
    .r-content {
        position: absolute;
        right: 0;
        top: 0;
        margin: 0;
        height: 100vh;
        max-width: 550px;
        width: 95%;
        background-color: #ffffff;
        .limit-box {
            width: 80%;
            max-width: 330px;
            margin: 0 auto;
            position: relative;
            height: calc(100vh - 160px);
            margin-top: 80px;
        }
        .title1 {
            font-size: 18px;
            width: 100%;
        }
        .title2 {
            font-size: 24px;
            width: 100%;
            margin-top: 5px;
        }
        .input-li {
            margin-top: 30px;
            .input-title {
                font-size: 12px;
                margin-bottom: 10px;
                color: #515151;
            }
            .input-body {
                border-color: #f4f4f4;
                height: 40px;
            }
        }

        .submit {
            width: 100%;
            position: absolute;
            bottom: 10px;
            height: 50px;
            // background-color: #0066ff;
            font-size: 14px;
        }
        .register {
            width: 100%;
            position: absolute;
            bottom: 70px;
            left: -10px;
            height: 50px;
            // background-color: #0066ff;
            font-size: 14px;
        }
    }
}

// @media screen and (max-width: 1300px) {
//   .content{
//     .l-content{
//       .limit-l{
//         width: calc(100% - 350px);
//       }
//       .limit-box{
//       width: 85%;
//       max-width: 1130px;
//       }
//       .m1{

//       }
//     }
//     .r-content{
//     max-width: 350px;
//     width: 95%;
//     }
//   }
// }
@media screen and (max-width: 900px) {
    .l-content {
        display: none !important;
    }
    .content .r-content .limit-box {
        margin: unset;
        top: 60px;
        max-width: unset !important;
        margin: 0 auto;
    }
    .r-content {
        // position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 100vw;
        background-color: #ffffff;
        .limit-box {
            // width: 80%;
            // max-width: 330px;

            position: relative;
            height: calc(100vh - 160px);
            margin-top: 80px;
        }
        .title1 {
            font-size: 18px;
            width: 100%;
        }
        .title2 {
            font-size: 24px;
            width: 100%;
            margin-top: 5px;
        }
        .input-li {
            margin-top: 30px;
            .input-title {
                font-size: 12px;
                margin-bottom: 10px;
                color: #515151;
            }
            .input-body {
                border-color: #f4f4f4;
                height: 40px;
            }
        }

        .submit {
            width: 100%;
            position: absolute;
            bottom: 80px !important;
            height: 30px;
            // background-color: #0066ff;
            font-size: 14px;
        }
        .register {
            width: 100%;
            position: absolute;
            bottom: 70px;
            // left:-10px;
            height: 30px;
            // background-color: #0066ff;
            font-size: 14px;
        }
    }
}
</style>
