<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <div class="layoutWidthIimit">
        <router-view :key="key"></router-view>
      </div>
    </transition>
  </section>
</template>

<script>
export default {
  name: "AppMain",
  computed: {
    key() {
      return this.$route.name !== undefined
        ? this.$route.name + +new Date()
        : this.$route + +new Date();
    },
  },
};
</script>
<style scoped>
.app-main {
  margin-top: 61px;
  min-height: calc(100vh - 61px);
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;
}
</style>