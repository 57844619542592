import Layout from '../../layout/Layout.vue';

// functype —— 控制侧边导航栏显示
const operationManagement = [
    {
        path: '/operation/error_report_manage',
        component: Layout,
        meta: {
            title: '系统上报管理',
            icon: '',
            notNeedAuth: true,
            functype: 3
        },
        children: [
            {
                path: 'unconfirmed',
                component: () =>
                    import(
                        '@/views/manage/operation/errorReportManage/index.vue'
                    ),
                name: 'error_report_index',
                meta: {
                    title: '待确认异常',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'all',
                component: () =>
                    import(
                        '@/views/manage/operation/errorReportManage/all.vue'
                    ),
                name: 'error_report_all',
                meta: {
                    title: '所有异常记录',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'detail',
                hidden: true,
                component: () =>
                    import(
                        '@/views/manage/operation/errorReportManage/detail.vue'
                    ),
                name: 'error_report_detail',
                meta: {
                    title: '机器人异常信息-详情',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            }
        ]
    },
    {
        path: '/operation/workOrder',
        component: Layout,
        meta: { title: '工单管理', icon: '', notNeedAuth: true, functype: 3 },
        children: [
            {
                path: 'hall',
                component: () =>
                    import(
                        '@/views/manage/operation/workOrderHall/workOrderAll.vue'
                    ),
                name: 'work_Hall',
                meta: {
                    title: '工单大厅',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'mywork',
                component: () =>
                    import(
                        '@/views/manage/operation/workOrderHall/workOrderDetail.vue'
                    ),
                name: 'work_My',
                meta: {
                    title: '我的工单',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'record',
                component: () =>
                    import(
                        '@/views/manage/personnelManagement/AccountGroup.vue'
                    ),
                name: 'work_Record',
                meta: {
                    title: '处理记录',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'detail',
                hidden: true,
                component: () =>
                    import(
                        '@/views/manage/operation/workOrderHall/workOrderDetail.vue'
                    ),
                name: 'work_Info',
                meta: {
                    title: '工单详情',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'processing',
                hidden: true,
                component: () =>
                    import(
                        '@/views/manage/operation/workOrderHall/workOrderProcessing.vue'
                    ),
                name: 'work_Processing',
                meta: {
                    title: '工单详情',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'create',
                hidden: true,
                component: () =>
                    import(
                        '@/views/manage/operation/workOrderHall/workOrderCreate.vue'
                    ),
                name: 'work_Create',
                meta: {
                    title: '创建工单',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            }
        ]
    },
    {
        path: '/operation/garage_parameters',
        component: Layout,
        redirect: '/',
        meta: { title: '车库管理', icon: '', notNeedAuth: true, functype: 3 },
        children: [
            {
                path: '',
                component: () =>
                    import(
                        '@/views/manage/operation/garageOperation/garageManage.vue'
                    ),
                name: 'garage_Query',
                meta: {
                    title: '车库管理',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'info',
                hidden: true,
                component: () =>
                    import(
                        '@/views/manage/operation/garageOperation/garageManageInfo.vue'
                    ),
                name: 'garage_Info',
                meta: {
                    title: '车库详情',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'parking',
                hidden: true,
                component: () =>
                    import(
                        '@/views/manage/operation/garageOperation/garageParkingManage.vue'
                    ),
                name: 'garage_Parking',
                meta: {
                    title: '车位管理',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            }
        ]
    },
    {
        path: '/operation/agv_managent',
        component: Layout,
        redirect: '/',
        meta: { title: '机器人管理', icon: '', notNeedAuth: true, functype: 3 },
        children: [
            {
                path: '',
                component: () =>
                    import(
                        '@/views/manage/operation/agvmanage/agvManageAll.vue'
                    ),
                name: 'agv_Manage',
                meta: {
                    title: '机器人管理',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'create',
                component: () =>
                    import(
                        '@/views/manage/operation/agvmanage/agvManageCreate.vue'
                    ),
                name: 'agv_Create',
                meta: {
                    title: '新增机器人',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'manufacturer',
                component: () =>
                    import(
                        '@/views/manage/operation/agvmanage/equipmentLibraryManufacturer.vue'
                    ),
                name: 'manufacturer_Manage',
                meta: {
                    title: '生厂商管理',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'info',
                hidden: true,
                component: () =>
                    import(
                        '@/views/manage/operation/agvmanage/agvManageInfo.vue'
                    ),
                name: 'agv_Info',
                meta: {
                    title: '机器人详情',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'check',
                hidden: true,
                component: () =>
                    import(
                        '@/views/manage/operation/agvmanage/agvManageCheck.vue'
                    ),
                name: 'agv_Check',
                meta: {
                    title: '机器人检查',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            }
        ]
    },
    {
        path: '/operation/product_managent',
        component: Layout,
        redirect: '/',
        meta: { title: '产品管理', icon: '', notNeedAuth: true, functype: 3 },
        children: [
            {
                path: '',
                component: () =>
                    import(
                        '@/views/manage/operation/productLibrary/productLibraryAll.vue'
                    ),
                name: 'product_Manage',
                meta: {
                    title: '所有产品',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'create',
                component: () =>
                    import(
                        '@/views/manage/operation/productLibrary/productLibraryCreate.vue'
                    ),
                name: 'product_Create',
                meta: {
                    title: '新增产品',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'info',
                hidden: true,
                component: () =>
                    import(
                        '@/views/manage/operation/productLibrary/productLibraryInfo.vue'
                    ),
                name: 'product_Info',
                meta: {
                    title: '产品详情',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'sort',
                component: () =>
                    import(
                        '@/views/manage/operation/productLibrary/productLibraryClassifyManage.vue'
                    ),
                name: 'product_Sort',
                meta: {
                    title: '产品类目管理',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3 
                }
            },
            {
                path: 'material',
                hidden: true,
                component: () =>
                    import(
                        '@/views/manage/operation/productLibrary/productLibraryInfoMateria.vue'
                    ),
                name: 'product_Material',
                meta: {
                    title: '资料',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            }
        ]
    },
    {
        path: '/operation/lifter_manage',
        component: Layout,
        redirect: '/',
        meta: { title: '升降机管理', icon: '', notNeedAuth: true, functype: 3 },
        children: [
            {
                path: '',
                component: () =>
                    import('@/views/manage/operation/lifterManage/index.vue'),
                name: 'lifter_Manage',
                meta: {
                    title: '升降机管理',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'lifter_manage_create',
                component: () =>
                    import('@/views/manage/operation/lifterManage/create.vue'),
                name: 'lifter_Create',
                meta: {
                    title: '新增升降机设备',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'lifter_manufacturer_manage',
                component: () =>
                    import(
                        '@/views/manage/operation/lifterManage/lifterProducer/index.vue'
                    ),
                name: 'lifter_Manufacturer_Manage',
                meta: {
                    title: '升降机厂商管理',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'lifter_manufacturer_product',
                hidden: true,
                component: () =>
                    import(
                        '@/views/manage/operation/lifterManage/lifterProducer/lifterProduct/index.vue'
                    ),
                name: 'lifter_Manufacturer_Product',
                meta: {
                    title: '升降机厂商产品管理',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'lifter_manufacturer_product_info',
                hidden: true,
                component: () =>
                    import(
                        '@/views/manage/operation/lifterManage/lifterProducer/lifterProduct/info.vue'
                    ),
                name: 'lifter_Manufacturer_Product_Info',
                meta: {
                    title: '升降机厂商产品信息',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'lifter_fault_resolvent',
                hidden: true,
                component: () =>
                    import(
                        '@/views/manage/operation/lifterManage/lifterFault/lifterFaultResolvent.vue'
                    ),
                name: 'lifter_Fault_Resolvent',
                meta: {
                    title: '升降机异常处理方法',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'lifter_manage_info',
                hidden: true,
                component: () =>
                    import('@/views/manage/operation/lifterManage/info.vue'),
                name: 'lifter_Info',
                meta: {
                    title: '升降机信息',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'lifter_fault_resolvent',
                hidden: true,
                component: () =>
                    import(
                        '@/views/manage/operation/lifterManage/lifterFault/lifterFaultResolvent.vue'
                    ),
                name: 'lifter_Fault_Resolvent',
                meta: {
                    title: '升降机异常处理方法',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            }
        ]
    },
    {
        path: '/operation/software_fault_managent',
        component: Layout,
        redirect: '/',
        meta: {
            title: '软件系统异常管理',
            icon: '',
            notNeedAuth: true,
            functype: 3
        },
        children: [
            {
                path: '',
                component: () =>
                    import(
                        '@/views/manage/operation/softwareFaultManagent/softwareAll.vue'
                    ),
                name: 'software_Fault_Manage',
                meta: {
                    title: '软件系统故障管理',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'software_fault_managent_info',
                hidden: true,
                component: () =>
                    import(
                        '@/views/manage/operation/softwareFaultManagent/softwareInfo.vue'
                    ),
                name: 'software_Fault_Info',
                meta: {
                    title: '异常详情',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'software_fault_managent_modulemanager',
                hidden: true,
                component: () =>
                    import(
                        '@/views/manage/operation/softwareFaultManagent/softwareModuleInfo.vue'
                    ),
                name: 'software_Fault_Info_modulemanager',
                meta: {
                    title: '异常模块管理',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'software_fault_managent_resolvent',
                hidden: true,
                component: () =>
                    import(
                        '@/views/manage/operation/softwareFaultManagent/softwareModuleFaultResolvent.vue'
                    ),
                name: 'software_Fault_Info_Resolvent',
                meta: {
                    title: '异常处理方法',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            }
        ]
    },
    {
        path: '/operation/equipment_fault_manage',
        component: Layout,
        redirect: '/',
        meta: {
            title: '常见故障收集和解决方法',
            icon: '',
            notNeedAuth: true,
            functype: 3
        },
        children: [
            {
                path: 'all',
                component: () =>
                    import(
                        '@/views/manage/operation/equipmentFaultManage/listAll.vue'
                    ),
                name: 'equipmentFaultManage_All',
                meta: {
                    title: '所有故障',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'search',
                component: () =>
                    import(
                        '@/views/manage/operation/equipmentFaultManage/start.vue'
                    ),
                name: 'equipmentFaultManage_Start',
                meta: {
                    title: '故障查询',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'sumbit',
                component: () =>
                    import(
                        '@/views/manage/operation/equipmentFaultManage/sumbitNewQuestion.vue'
                    ),
                name: 'equipmentFaultManage_Sumbit',
                meta: {
                    title: '提交故障处理方法',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'type_manage',
                component: () =>
                    import(
                        '@/views/manage/operation/equipmentFaultManage/typeManage.vue'
                    ),
                name: 'equipmentFaultManage_typeManage',
                meta: {
                    title: '故障类目管理',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'recent_update',
                component: () =>
                    import(
                        '@/views/manage/operation/equipmentFaultManage/recentUpdate.vue'
                    ),
                name: 'equipmentFaultManage_recentUpdate',
                meta: {
                    title: '近期更新动态',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'submit_history',
                component: () =>
                    import(
                        '@/views/manage/operation/equipmentFaultManage/submitHistory.vue'
                    ),
                name: 'equipmentFaultManage_submitHistory',
                meta: {
                    title: '我的贡献',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'info',
                component: () =>
                    import(
                        '@/views/manage/operation/equipmentFaultManage/info.vue'
                    ),
                name: 'equipmentFaultManage_info',
                hidden: true,
                meta: {
                    title: '故障信息',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'update',
                component: () =>
                    import(
                        '@/views/manage/operation/equipmentFaultManage/update.vue'
                    ),
                name: 'equipmentFaultManage_update',
                hidden: true,
                meta: {
                    title: '更新信息',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            }
        ]
    },
    {
        path: '/operation/equipment_management',
        component: Layout,
        redirect: '/',
        meta: {
            title: '硬件设备管理',
            icon: '',
            notNeedAuth: true,
            functype: 3
        },
        children: [
            {
                path: '',
                component: () =>
                    import(
                        '@/views/manage/operation/equipmentLibrary/equipmentLibraryAll.vue'
                    ),
                name: 'equipment_Manage',
                meta: {
                    title: '所有设备',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'equipment_info',
                hidden: true,
                component: () =>
                    import(
                        '@/views/manage/operation/equipmentLibrary/equipmentLibraryInfo.vue'
                    ),
                name: 'equipment_info',
                meta: {
                    title: '设备详情',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'equipment_edit',
                hidden: true,
                component: () =>
                    import(
                        '@/views/manage/operation/equipmentLibrary/equipmentLibraryInfo.vue'
                    ),
                name: 'equipment_edit',
                meta: {
                    title: '设备附件编辑',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'equipment_materia',
                hidden: true,
                component: () =>
                    import(
                        '@/views/manage/operation/equipmentLibrary/equipmentLibraryInfoMateria.vue'
                    ),
                name: 'equipment_materia',
                meta: {
                    title: '设备资料编辑',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'equipment_sort',
                component: () =>
                    import(
                        '@/views/manage/operation/equipmentLibrary/equipmentLibraryTypeManage.vue'
                    ),
                name: 'equipment_Sort_Manage',
                meta: {
                    title: '设备类型类目管理',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'brand_sort',
                component: () =>
                    import(
                        '@/views/manage/operation/equipmentLibrary/equipmentLibraryBrand.vue'
                    ),
                name: 'brand_Sort_Manage',
                meta: {
                    title: '品牌类目管理',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            }
        ]
    },
    {
        path: '/operation/car_infobase',
        component: Layout,
        redirect: '/',
        meta: { title: '车辆信息库', icon: '', notNeedAuth: true, functype: 3 },
        children: [
            {
                path: '',
                component: () =>
                    import(
                        '@/views/manage/operation/carManage/carLibraryAll.vue'
                    ),
                name: 'carinfo_Existed',
                meta: {
                    title: '已有车辆信息',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'create',
                component: () =>
                    import(
                        '@/views/manage/operation/carManage/carInfoCreate.vue'
                    ),
                name: 'carinfo_Create',
                meta: {
                    title: '新增车辆信息',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'brand',
                component: () =>
                    import(
                        '@/views/manage/operation/carManage/carLibraryBrand.vue'
                    ),
                name: 'carinfo_Brand',
                meta: {
                    title: '车辆品牌管理',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'warning',
                component: () =>
                    import(
                        '@/views/manage/operation/productLibrary/productLibraryClassifyManage.vue'
                    ),
                name: 'carinfo_Warning',
                meta: {
                    title: '车辆异常标定',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            }
        ]
    },
    {
        path: '/operation/task_records',
        component: Layout,
        redirect: '/',
        meta: {
            title: '云端运行记录',
            icon: '',
            notNeedAuth: true,
            functype: 3
        },
        children: [
            {
                path: '',
                component: () =>
                    import(
                        '@/views/manage/operation/taskmanage/taskManageAll.vue'
                    ),
                name: 'task_Manage',
                meta: {
                    title: '云端任务下发记录',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            },
            {
                path: 'warning',
                component: () =>
                    import(
                        '@/views/manage/operation/taskmanage/taskNowManageAll.vue'
                    ),
                name: 'task_Warning',
                meta: {
                    title: '云端异常警告',
                    icon: '',
                    notNeedAuth: true,
                    functype: 3
                }
            }
        ]
    }
    // {
    //     path: '/operation/equipment_records',
    //     component: Layout,
    //     redirect: '/',
    //     meta: { title: '设备运行记录', icon: '', notNeedAuth: true, functype: 3},
    //     children: [
    //         {
    //             path: '',
    //             component: () => import('@/views/manage/business/partherAccountManage/partnerAccountManage.vue'),
    //             name: 'agv_Record',
    //             meta: { title: '机器人日志', icon: '', notNeedAuth: true, functype: 3}
    //         },
    //         {
    //             path: 'create',
    //             component: () => import('@/views/manage/business/partherAccountManage/partnerAccountCreate.vue'),
    //             name: 'garage_Record',
    //             meta: { title: '车库日志', icon: '', notNeedAuth: true, functype: 3}
    //         }
    //     ]
    // },
    // {
    //     path: '/operation/equipment_management',
    //     component: Layout,
    //     redirect: '/',
    //     meta: { title: '官网管理', icon: '', notNeedAuth: true, functype: 3 },
    //     children: [
    //         {
    //             path: '',
    //             component: () =>
    //                 import(
    //                     '@/views/manage/operation/equipmentLibrary/equipmentLibraryAll.vue'
    //                 ),
    //             name: 'culturalWall_Manage',
    //             meta: {
    //                 title: '文化墙管理',
    //                 icon: '',
    //                 notNeedAuth: true,
    //                 functype: 3
    //             }
    //         }
    //     ]
    // }
];

export { operationManagement };
